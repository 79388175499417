<template>

    <div v-if="dataSource" class="p-relative">

        <Toolbar :menu="dataSource.Data.Menu" :isList="true" v-on:toolbar-button-click="onToolbarClick">
            <div class="top-btns-bar-right-section">
                <v-text-field
                    v-model="search"
                    append-icon="fas fa-search"
                    :label="$t('Что_ищем')"
                    hide-details
                    class="search-in-tooltip"
                    solo
                    flat
                    clearable
                ></v-text-field>
            </div>
        </Toolbar>

        <FilterPanel
            v-if="filterDataSource"
            :dataSource="filterDataSource"
            v-bind:status.sync="category"
        >
        </FilterPanel>

        <v-row no-gutters>
            <v-col cols="12" xs="12" md="12">
                <v-data-table
                    :headers="headers"
                    :loading="loading"
                    :items="projects"
                    :options.sync="options"
                    :server-items-length="total"
                    :items-per-page="10"
                    :footer-props="{ itemsPerPageOptions:[ 5, 10, 20] }"
                    :page.sync="options.page"
                    @page-count="paginationPageCount = $event"
                    class="elevation-1 customization-list-table pagination-list-table sticky-table"
                    @dblclick:row="(event, data) => $eventBus.$emit('open-document', { id: data.item.id, type: 'Chancellery|Documents.Document', common: data.item.iscommon })">
                    <v-progress-linear slot="progress" height="2" color="#E43958" indeterminate></v-progress-linear>

                    <template v-slot:[`item.theme`]="{ item }">
                        <div class="elipsis-text-wrap">
                            <v-common-chip v-if="item.iscommon === true" :document-type="item.doctypeof"/>
                            <v-doc-type-chip :type="item.doctypeof" :text="item.doctype"/>
                            <div class="etw-text-container">
                                <div class="etw-text" v-tooltip.top-center="item.theme">
                                    {{item.theme}}
                                </div>
                            </div>
                        </div>
                    </template>

                    <template v-slot:[`item.regdate`]="{ item }">
                        {{ formatDate(item.regdate) }}
                    </template>

                    <template v-slot:[`item.createdate`]="{ item }">
                        {{ formatDate(item.createdate) }}
                    </template>

                    <template v-slot:[`item.draftstatus`]="{ item }">
                        <StatusChip :status="avaibleStatuses[$helpers.getDraftStatus(item.draftstatus)]"/>
                    </template>

                    <template v-slot:[`footer.prepend`]>
                        <v-pagination
                            v-model="options.page"
                            :length="paginationPageCount"
                            :total-visible="$listPaginationTotal"
                            color="teal"
                        ></v-pagination>
                    </template>

                </v-data-table>
            </v-col>

        </v-row>
    </div>
</template>

<script>
import _ from 'lodash';
import i18n from '@/i18n'
import Axios from 'axios';
import { httpAPI } from "@/api/httpAPI";
import FilterPanel from '@/components/FilterPanel.vue';
import { mapActions } from 'vuex';
import Toolbar from '@/components/Toolbar.vue';
import StatusChip from '@/components/StatusChip';

export default {
    name: "ProjectsList",
    columnIndex:  {
        'createdate': 3, 
        'theme': 4, 
        'regnumber': 6, 
        'regdate': 7, 
        'version': 8
    },
    components: {
        FilterPanel,
        Toolbar,
        StatusChip
    },
    data () {
        return {
            cancellationTokenSorce: null,
            loading: true,
            total: 0,
            projects: [],
            dataSource: null,
            filterDataSource: null,
            prevTableFilterObject: null,
            paginationPageCount: 0,
        }
    },
    computed: {
        options: {
            get: function() {
                return this.$store.getters['projects/GetTableOptions'];
            },
            set: function(newValue) {
                this.$store.commit('projects/SET_TABLE_OPTION', newValue);
            }
        },
        search: {
            get: function() {
                return this.$store.getters['projects/GetTableSearch'];
            },
            set: _.debounce(function(v) {
                this.$store.commit('projects/SET_TABLE_OPTION_PAGE', 1);
                this.$store.commit('projects/SET_TABLE_SEARCH', v);
            }, 500)
        },
        category: {
            get: function() {
                return this.$store.getters['projects/GetCategory'];
            },
            set: function(newValue) {
                this.$store.commit('projects/SET_CATEGORY', newValue);
                this.$store.commit('projects/SET_TABLE_OPTION_PAGE', 1);
            }
        },
        filter () {
            return {
                ...this.options,
                SearchStr: this.search,
                MyDraftStatus: this.category,
            }
        },
        headers() {
            const headers = [
                { text: i18n.t("Номер"), value: 'regnumber', width: '150px' },
                { text: i18n.t("Рег._дата"), value: 'regdate', width: '110px' },
                { text: i18n.t("Краткое_содержание"), value: 'theme' },
                { text: i18n.t("Дата_создания"), value: 'createdate', width: '150px' },
                { text: i18n.t("Статус"), value: 'draftstatus', width: '150px', sortable: false }
            ];

            if (this.filter.MyDraftStatus == "5") {
                return headers.filter(h => !['createdate', 'draftstatus'].includes(h.value));
            }

            if (this.filter.MyDraftStatus == "-1" || this.filter.MyDraftStatus == "2") {
                return headers.filter(h => !['regdate'].includes(h.value));
            }

            return headers.filter(h => !['regdate', 'draftstatus'].includes(h.value));
        },
        avaibleStatuses() {
            return this.$store.getters['references/GetAvaibleStatuses'];
        }
    },
    watch: {
        filter: {
            handler () {
                this.getData()
            },
            deep: true,
        },
    },
    methods:
    {
        ...mapActions(['setOverlayVisible']),
        async onToolbarClick (event, btn) {
            this[btn.Action]();
        },
        async getData(isRefresh = false)
        {
            if (isRefresh === false && this.$_.isEqual(this.prevTableFilterObject, this.getTableFilterObject()))
                return;
            
            this.setOverlayVisible({ visible: true });    

            this.prevTableFilterObject = this.getTableFilterObject();

            this.total = 0;
            this.projects = [];
            this.loading = true;

            if (this.cancellationTokenSorce)
                this.cancellationTokenSorce.cancel('New request started');

            this.cancellationTokenSorce = Axios.CancelToken.source();
            

            let response = await httpAPI({
                url: `api/projects/list?filter=${JSON.stringify(this.getTableFilterObject())}`,
                method: 'GET',
                cancelToken: this.cancellationTokenSorce.token,
            });

            this.total = response?.data?.payload?.total ?? 0;
            this.projects = response?.data.payload?.data ?? [];
            
            this.loading = false;
            this.setOverlayVisible({ visible: false });
        },
        getTableFilterObject() {
            let { sortBy, sortDesc, page, itemsPerPage, SearchStr, MyDraftStatus } = this.filter;
            let OrderDir = sortDesc.length <= 0 ? false : sortDesc[0];
            let sortColumn = sortBy.length <= 0 ? "createdate" : sortBy[0];
            let OrderBy = this.$options.columnIndex[sortColumn] ?? 3;

            return { MyDraftStatus, SearchStr, OrderBy, OrderDir, Limit: itemsPerPage, Offset: (page - 1) * itemsPerPage };
        },
        formatDate (value) {
            if (!value)
                return '';

            return this.$moment(value).format('DD.MM.YYYY');
        },
        async Refresh() {

            let filterResponse = await httpAPI({
                url: `api/projects/filter`,
                method: 'GET',
                cancelToken: this.cancellationTokenSorce.token,
            });
            
            if (filterResponse)
                this.filterDataSource = filterResponse.data.payload;

            await this.getData(true);
        }
    },    
    async created() {
        this.setOverlayVisible({ visible: true });

        if (this.cancellationTokenSorce)
            this.cancellationTokenSorce.cancel('New request started');

        this.cancellationTokenSorce = Axios.CancelToken.source();

        let response = await httpAPI({
            url: `api/actions/collection?type=Documents.Draft`,
            method: 'GET',
            cancelToken: this.cancellationTokenSorce.token,
        });

        if (response) {
            let filterResponse = await httpAPI({
                url: `api/projects/filter`,
                method: 'GET',
                cancelToken: this.cancellationTokenSorce.token,
            });
            
            if (filterResponse)
                this.filterDataSource = filterResponse.data.payload;

            this.dataSource = response.data.payload;
        }        

        this.setOverlayVisible({ visible: false });
    },
}
</script>